<template>
  <v-card>
    <v-toolbar dense color="transparent">
      <v-spacer />
      <v-btn color="info" @click="mostrarModal('nuevo', 0)">{{ $t('producto.nuevo') }}</v-btn>
    </v-toolbar>
    <div v-if="loading" class="loading_producto">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <v-card v-if="!loading">
      <v-toolbar color="white">
        <v-text-field
          v-model="search"
          outlined
          prepend-icon="mdi-magnify"
          :placeholder="this.$t('producto.buscar')"
          hide-details
          class="hidden-sm-and-down"
        />
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :search="search"
          :items="productos"
          :items-per-page-options="[10, 25, 50, { text: $t('datatable.all'), value: -1 }]"
          class="elevation-1"
          :items-per-page-text="datatable.rows_per_page"
          item-key="name"
        >
          <template v-slot:item="props">
            <tr style="cursor: pointer" @click="abrirFicha(props.item.id)">
              <td>{{ props.item.nombre === null ? props.item.name : props.item.nombre }}</td>
              <td>{{ props.item.licencias }}</td>
              <td>{{ props.item.precio }}</td>
            </tr>
          </template>

          <template slot="no-data"> <v-icon>mdi-alert</v-icon> {{ $t('common.no_registros') }} </template>

          <template slot="pageText" slot-scope="props">
            {{ $t('menu.productos') }} {{ props.pageStart }} - {{ props.pageStop }} {{ $t('common.of') }}
            {{ props.itemsLength }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card-text class="pa-0">
      <!-- Modal de Nuevo Producto -->
      <span slot="widget-content">
        <v-dialog v-model="dialog_new.dialog" persistent :overlay="false" scrollable max-width="700px">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click.native="dialog_new.dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ dialog_new.titulo }}</v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <form class="form_producto_nuevo">
              <v-layout wrap>
                <v-flex v-if="producto.id > 0" sm12>
                  <v-alert :value="true" type="info">
                    Stripe no permite editar el precio de un Producto via API
                  </v-alert>
                </v-flex>
                <v-flex sm12>
                  <v-text-field
                    v-model="producto.nombre"
                    class="mr-2"
                    :label="$t('common.name')"
                    type="text"
                    required
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-model="producto.licencias"
                    class="mr-2"
                    :label="$t('producto.licencia')"
                    type="number"
                    step="1"
                    required
                  />
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-if="producto.id > 0"
                    v-model="producto.precio"
                    class="mr-2"
                    readonly="readonly"
                    :label="$t('common.precio')"
                    type="number"
                    step="0.01"
                    required
                  />
                  <v-text-field
                    v-else
                    v-model="producto.precio"
                    class="mr-2"
                    :label="$t('common.precio')"
                    type="number"
                    step="0.01"
                    required
                  />
                </v-flex>
                <v-flex sm12>
                  <v-text-field
                    v-model="producto.descripcion"
                    class="mr-2"
                    :label="$t('common.descripcion')"
                    type="text"
                    required
                  />
                </v-flex>
                <v-flex sm12>
                  <v-btn
                    color="blue"
                    class="white--text"
                    @click="
                      dialog = false;
                      crearProducto();
                    "
                    >{{ $t('common.save') }}</v-btn
                  >
                </v-flex>
              </v-layout>
            </form>
          </v-card>
        </v-dialog>
      </span>

      <!-- Modal Eliminar producto -->
      <span slot="widget-content">
        <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('patient.delete') }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <p>{{ $t('patient.confirm_delete') }}</p>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false"
                ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
              >
              <v-btn
                color="red darken-1"
                text
                @click.native="
                  dialog_delete.dialog = false;
                  borrarProductos(producto.id);
                "
                ><v-icon>mdi-delete</v-icon> {{ $t('common.confirm') }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </span>
      <!-- Fin Modal -->
      <v-divider />
    </v-card-text>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { productosUrl, createProductoUrl, deleteProductoUrl, getHeader } from '../config/config';

export default {
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    search: '',
    snackbar: false,
    timeout: 5000,
    mode: '',
    producto: {
      valid: true,
      id: 0,
      nombre: '',
      descripcion: '',
      licencias: '',
      precio: '',
    },
    producto_defect: {
      valid: true,
      id: 0,
      nombre: '',
      descripcion: '',
      licencias: '',
      precio: '',
    },
    dialog_delete: {
      name: '',
      dialog: false,
    },
    dialog_new: {
      dialog: false,
      titulo: '',
      notifications: false,
      sound: true,
      widgets: false,
    },
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
      tipo: 'outlined',
    },
    headers: [
      { text: 'Nombre', align: 'left', value: 'nombre' },
      { text: 'Num. Licencias', align: 'left', value: 'licencias' },
      { text: 'Precio', align: 'left', value: 'precio' },
    ],
    estados: [
      { color: 'red accent-1', label: 'NO' },
      { color: 'green lighten-1', label: 'SI' },
    ],
    productos: [],
    datatable: {
      rows_per_page: 'Rows per Page',
    },
  }),

  created() {
    this.$http.post(productosUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
      this.productos = response.data.productos;
      this.loading = false;

      // traducciones
      this.headers[0]['text'] = this.$t('common.name');
      this.headers[1]['text'] = this.$t('producto.licencia');
      this.headers[2]['text'] = this.$t('common.precio');
      this.datatable.rows_per_page = this.$t('datatable.rows_per_page');
    });
  },

  methods: {
    listarProductos() {
      this.$http.post(productosUrl, { id: this.authUser.id }, { headers: getHeader() }).then(response => {
        this.productos = response.data.productos;
      });
    },

    mostrarModal(tipo, id) {
      if (tipo === 'delete') {
        this.dialog_delete.dialog = true;
      } else if (tipo === 'nuevo') {
        this.dialog_new.titulo = this.$t('producto.nuevo');
        this.producto = this.producto_defect;
        this.dialog_new.dialog = true;
      }
      this.producto.id = id;
    },

    abrirFicha(id) {
      let p = this.producto_defect;
      this.productos.forEach(function(element) {
        if (element.id === id) {
          p = element;
        }
      });
      this.dialog_new.titulo = this.$t('producto.editar');
      this.producto = p;
      this.dialog_new.dialog = true;
    },

    borrarProductos(id) {
      this.$http
        .post(deleteProductoUrl, { id: this.authUser.id, patient_id: id }, { headers: getHeader() })
        .then(response => {
          this.alerta.icono = 'mdi-delete';
          if (response.status === 200 && response.data === '1') {
            this.alerta.texto = this.$t('patient.delete_success');
            this.alerta.color = 'info';
            this.listarProductos();
          } else {
            this.alerta.texto = this.$t('patient.delete_error');
            this.alerta.color = 'error';
          }
        });

      setTimeout(() => {
        this.alerta.texto = '';
      }, 5000);
    },

    validarFormulario() {
      this.alerta.texto = '';
      this.snackbar = false;

      if (this.producto.nombre.length < 3) {
        this.alerta.texto = this.$t('producto.nombre_required');
      }
      if (this.producto.precio === '') {
        this.alerta.texto = this.$t('producto.precio_required');
      }
      return this.alerta.texto !== '' ? false : true;
    },

    crearProducto() {
      const es_valido = this.validarFormulario();
      if (!es_valido) {
        this.alerta.icono = 'mdi-alert-circle';
        this.alerta.color = 'warning';
        this.snackbar = true;
      } else {
        this.$http
          .post(createProductoUrl, { id: this.authUser.id, producto: this.producto }, { headers: getHeader() })
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.alerta.texto = this.$t('producto.creado');
              this.alerta.color = 'success';
              this.alerta.icono = 'mdi-check';
              if (this.producto.id > 0) {
                // editar
                this.productos.forEach((element, key) => {
                  if (element.id === response.data.id) {
                    this.productos[key] = this.producto;
                  }
                });
              } else {
                // crear
                this.producto.id = response.data.id;
                this.productos.push(this.producto);
              }
              this.dialog_new.dialog = false;
            } else if (response.data.estado === 2) {
              this.alerta.texto = this.$t('errors.existing_user');
              this.alerta.color = 'error';
              this.alerta.icono = 'mdi-alert-circle';
            } else {
              this.alerta.texto = this.$t('errors.try_again');
              this.alerta.color = 'error';
              this.alerta.icono = 'mdi-alert-circle';
            }
            this.snackbar = true;
          });
      }
    },
  },
};
</script>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
.loading_producto {
  margin-top: 30px;
  text-align: center;
}
.form_producto_nuevo {
  padding: 10px 30px;
}
.label_genero {
  margin-bottom: 0px;
}
.radio_genero {
  margin-top: 3px;
}
.titulo_modal {
  margin-top: 0px !important;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
